<template>
  <div>
    <h4 class="text-center mt-5">Logging in...</h4>
  </div>
</template>

<script>
export default {
  mounted() {
    this.zendeskLogin();
  },
  methods: {
    zendeskLogin() {
      this.$store
        .dispatch("helpdesk/sso", { return_to: this.$route.query.return_to })
        .then(this.processData)
        .catch(this.catchData);
    },
    processData(response) {
      var form = document.createElement("form");
      var elJwt = document.createElement("input");
      var elReturnTo = document.createElement("input");

      form.method = "POST";
      form.action = "https://turbobit-net.zendesk.com/access/jwt";

      elJwt.value = response.data.token;
      elJwt.name = "jwt";
      form.appendChild(elJwt);

      elReturnTo.value = response.data.returnTo;
      elReturnTo.name = "return_to";
      form.appendChild(elReturnTo);

      document.body.appendChild(form);

      form.submit();
    },
    catchData() {
      this.$store.dispatch("app/showDialog", {
        type: "error",
        title: "Error",
        message: "Failed getting data"
      });
    }
  }
};
</script>
